import React from "react";

class Sixward extends React.Component {
    render() {
        return(


<div class="nk-wrap">
        <main class="nk-pages">

                <section class="section bg-white bg-blend-light-alt" id="wezure">
                <div class="container">
                    <div class="section-head text-center wide-auto-sm">
                        <div class="section-head-line">
                            <span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span>
                            <span class="line-5"></span><span class="line-6"></span><span class="line-7"></span><span class="line-8"></span>
                        </div>
                        <h2 class="title title-s4 animated" data-animate="fadeInUp" data-delay=".1">How to join</h2>
                        <p class="animated" data-animate="fadeInUp" data-delay=".2">Join us for NFTs project development</p>
                    </div>
                    
                    <div class="nk-block nk-block-text-wrap">
                        <div class="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                            <div class="col-mb-9 col-sm-7 col-md-6">
                                <div class="nk-block-img animated" data-animate="fadeInUp" data-delay=".3">
                                    <img src="images/light/wezuretoken.png" alt="wzu" />
                                </div>
                            </div>
                            <div class="col-sm-10 col-md-6 col-lg-5">
                                <div class="nk-block-text ms-lg-3 ms-xl-0">
                                    <p class="animated" data-animate="fadeInUp" data-delay=".4">Only 3 steps are required for you to participate in wezure</p>
                                    <ul class="list list-dot pdb-r">
                                        <li class="animated" data-animate="fadeInUp" data-delay=".5">Purchase $WZU Token in Uniswap</li>
                                        <li class="animated" data-animate="fadeInUp" data-delay=".55">Holders token to participate</li>
                                        <li class="animated" data-animate="fadeInUp" data-delay=".60">You're all set!</li>
                                    </ul>
                                    <ul class="btn-grp justify-content-center justify-content-md-start">
                                        <li class="animated" data-animate="fadeInUp" data-delay=".85"><a href="https://polygonscan.com/token/0x1c0877c0ec4ede0abcf44ed05b3342e4e85c4395" class="btn btn-grad btn-round btn-md">Polygonscan</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>

                <section class="section bg-white bg-blend-light-alt">
                    <div class="container">
                        <div class="nk-block nk-block-text-wrap">
                            <div class="row align-items-center gutter-vr-30px justify-content-lg-between justify-content-center">
                                <div class="col-lg-5 col-mb-10 order-lg-last">
                                    <div class="nk-block-img animated" data-animate="fadeInUp" data-delay=".3">
                                        <img src="images/light/watching-tv.svg" alt="wzu"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="nk-block-text">
                                        <h2 class="title title-regular">WZU IPTV</h2>
                                        <p>The best performing live streaming experience.</p>
                                        <a href="https://iptv.sixward.my.id" class="btn btn-secondary btn-md">more <em class="ti ti-arrow-right ps-2"></em></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section class="section">
                    <div class="container">
                        <div class="nk-block nk-block-text-wrap">
                            <div class="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                                <div class="col-mb-9 col-sm-7 col-md-6">
                                    <div class="nk-block-img animated">
                                        <div class="gfx-round-bg"></div>
                                        <img src="images/light/web-vector-cloud-browse-apps.svg" alt="hotspot"/>
                                    </div>
                                </div>
                                <div class="col-sm-10 col-md-6 col-lg-5">
                                    <div class="nk-block-text">
                                        <h2 class="title title-regular">WZU Hotspot</h2>
                                        <p>High-Speed, Secure & Anonymous WiFi Service.</p>
                                        <a href="https://hotspot.sixward.my.id" class="btn btn-secondary btn-md">more <em class="ti ti-arrow-right ps-2"></em></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section class="section bg-white bg-blend-light-alt" id="projects">
                    <div class="container">
                        <div class="nk-block nk-block-feature">
                            <div class="section-head text-center wide-auto-sm">
                                <h2 class="title title-s5 animated" data-animate="fadeInUp" data-delay=".1">Decentralized Exchanges</h2>
                                <p class="animated" data-animate="fadeInUp" data-delay=".2">A decentralized exchange (or DEX) is a peer-to-peer marketplace where transactions occur directly between crypto traders. </p>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">Uniswap (V3)</h5>
                                            <p>Uniswap is one of the most popular cryptocurrency decentralized exchanges (DEXs), launching in November 2018. It pioneered the automated market maker (AMM) model, instead of the traditional order book-based used by exchanges.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-mb-10">
                                    <div class="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap">
                                        <div class="feature-text">
                                            <h5 class="title title-md">KyberSwap</h5>
                                            <p>KyberSwap is the best place to trade more than 23,000 tokens on networks at the best rates, and earn more with your token assets. Make multi-chain swaps (Ethereum, Polygon, BNB Chain, Avalanche, Cronos, Fantom, Arbitrum, BTTC & more) without needing to check rates across multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        </main>
        <footer class="nk-footer bg-light">

            <div class="section section-footer section-s bg-transparent">
                <div class="container">
                    <div class="nk-block block-footer">
                        <div class="row">
                            <div class="col">
                                <div class="wgs wgs-text text-center mb-3">
                                    <ul class="social pdb-m justify-content-center">
                                        <li><a href="https://www.facebook.com/wezure"><em class="social-icon fab fa-facebook-f"></em></a></li>
                                        <li><a href="https://twitter.com/wezuretoken"><em class="social-icon fab fa-twitter"></em></a></li>
                                        <li><a href="https://www.instagram.com/wezuretoken"><em class="social-icon fab fa-instagram"></em></a></li>
                                    </ul>
                                    <div class="copyright-text copyright-text-s3 pdt-m">
                                        <p><span class="d-sm-block">2022 &copy; <a href="./">Sixward</a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>


)
}
}

export default Sixward;